import { useGetErrorInfo } from '@experiences/error';
import {
    getDominantPersona,
    PERSONA_PRIORITY_ORDER,
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useUserInfo from '../../auth/hooks/UserInfo';
import {
    licenseTypes,
    notificationType,
    UserRole,
} from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import { deactivateLicenseOnline } from '../../services/licensing/management/ActivationService';
import { getPersona } from '../../services/organization/Persona.default';
import { getUserOrganizationInfo } from '../../services/organization/UserOrganizationInfoService.default';
import { setUserProfile } from '../../store/action/UserProfileAction';
import {
    isHostModeSelector,
    profile,
} from '../../store/selectors';
import { UiDropdownButton } from '../common/UiDropdownButton/UiDropdownButton';
import LicenseActivationErrorDialogBody from './subcomponents/LicenseActivationErrorDialogBody';

const useStyles = makeStyles(() =>
    createStyles({
        buttons: { display: 'flex' },
        button: { marginLeft: '8px' },
    }),
);

const LicenseHeaderActionComponent: React.FC = () => {
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { getErrorObject } = useGetErrorInfo();
    const createNotification = useUiSnackBar();
    const createDialog = useShowDialog();
    const accountProfile = useSelector(profile);
    const isHostMode = useSelector(isHostModeSelector);
    const licenseType = useMemo(
        () => accountProfile.accountUserDto.licenseType,
        [ accountProfile.accountUserDto.licenseType ],
    );
    const { licenseCode } = useMemo(() => accountProfile.accountUserDto, [ accountProfile ]);
    const { token } = useUserInfo();

    const deactivateOnline = useCallback(async () => {
        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_DEACTIVATE_LICENSE' }),
            body: translate({ id: 'CLIENT_ONLINE_DEACTIVATE_CONFIRM' }),
            icon: 'warning',
            primaryButtonText: translate({ id: 'CLIENT_DEACTIVATE_ACTION' }),
            showCancel: true,
        });

        if (proceed) {
            try {
                const response = await deactivateLicenseOnline(licenseCode, isHostMode);
                if (response.operationStatus === 0) {
                    createNotification(translate({ id: 'CLIENT_DEACTIVATE_SUCCESSFULLY' }), notificationType.SUCCESS);
                }

                const [ userOrganizationInfo, personaFit ] = await Promise.all([
                    getUserOrganizationInfo(token),
                    getPersona(token),
                ]);
                if (userOrganizationInfo && personaFit) {
                    userOrganizationInfo.persona = getDominantPersona(
                        personaFit,
                        userOrganizationInfo?.accountRoleType !== UserRole.DEFAULT_USER,
                        userOrganizationInfo?.accountUserDto?.accountType,
                        PERSONA_PRIORITY_ORDER);
                    await setUserProfile(userOrganizationInfo);
                }
            } catch (error) {
                const errorObject = await getErrorObject(error);
                if (errorObject.response?.status === 422) {
                    await createDialog({
                        title: translate({ id: 'CLIENT_ACTIVATE_FAILED' }),
                        icon: 'error',
                        unclosable: false,
                        showCancel: false,
                        customDialogContent: LicenseActivationErrorDialogBody,
                        customDialogContentProps: { operationStatus: errorObject.response.data.operationStatus },
                    });
                }
            }
        }
    }, [ createDialog, translate, licenseCode, isHostMode, token, createNotification, getErrorObject ]);

    return (
        <>
            {licenseType !== licenseTypes.HOSTED && (
                <div className={classes.buttons}>
                    <div className={classes.button}>
                        <UiDropdownButton
                            label={translate({ id: 'CLIENT_DEACTIVATE_LICENSE' })}
                            menuItems={[
                                {
                                    onClick: () => deactivateOnline(),
                                    label: translate({ id: 'CLIENT_ONLINE_DEACTIVATION' }),
                                },
                                {
                                    onClick: () => {
                                        navigate(getRoute(RouteNames.DeactivateOffline));
                                    },
                                    label: translate({ id: 'CLIENT_OFFLINE_DEACTIVATION' }),
                                },
                            ]}
                        />
                    </div>
                    <div className={classes.button}>
                        <UiDropdownButton
                            label={translate({ id: 'CLIENT_LICENSE_EXPIRED_ACTION_BANNER' })}
                            menuItems={[
                                {
                                    onClick: () => {
                                        navigate(getRoute(RouteNames.LicensingActivateLicenseOnline),
                                            {
                                                state: {
                                                    previousLocation: location.pathname,
                                                    isUpdateLicense: true,
                                                },
                                            });
                                    },
                                    label: translate({ id: 'CLIENT_ONLINE_ACTIVATION' }),
                                },
                                {
                                    onClick: () => {
                                        navigate(getRoute(RouteNames.ActivateOffline),
                                            {
                                                state: {
                                                    previousLocation: location.pathname,
                                                    isUpdateLicense: true,
                                                },
                                            });
                                    },
                                    label: translate({ id: 'CLIENT_OFFLINE_ACTIVATION' }),
                                },
                            ]}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default LicenseHeaderActionComponent;
