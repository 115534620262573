import { useGetErrorInfo } from '@experiences/error';
import { useLocalization } from '@experiences/locales';
import { UiProgressButton } from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { accountSetting } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import type { IAddOrganizationData } from '../../../common/interfaces/organization';
import {
    addOnPremOrganization,
    getAllOnPremOrganizations,
} from '../../../services/organization/OnPremOrganizationService';
import { mapOnPremOrganizationDto } from '../../../util/OnPremOrganizationUtil';
import validateAccountLogicalName from '../../../util/validators/AccountLogicalNameValidator';
import validateEmail from '../../../util/validators/EmailValidator';
import EditPasswordFormComponent, { defaultEditPasswordData } from '../../common/EditPasswordFormComponent';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';

const useStyles = makeStyles(() =>
    createStyles({
        body: { margin: '0px 24px' },
        input: { marginTop: 20 },
        inlineIcon: {
            verticalAlign: 'middle',
            paddingBottom: '3px',
        },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
);

const AddOrganizationComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { getErrorMessage } = useGetErrorInfo();
    const {
        open, close,
    } = useModalState(RouteNames.Organizations);
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(translate({ id: 'CLIENT_ADD_ORGANIZATION_GENERIC_ERROR' }));
    const [ takenUsername, setTakenUsername ] = useState<string>();
    const methods = useForm<IAddOrganizationData>({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultEditPasswordData,
            organizationName: '',
            adminUserName: '',
            adminFirstName: '',
            adminLastName: '',
            adminEmail: '',
        },
    });
    const language = useLocalization();
    const {
        formState: {
            errors, isDirty,
        }, control, trigger, handleSubmit,
    } = methods;

    const isOrganizationNameTaken = useCallback(async (organizationName: string) => {
        const response = await getAllOnPremOrganizations({ url: '' });
        return response.results.some(result => result.name?.toLowerCase() === organizationName.toLowerCase());
    }, []);

    const onSubmit = useCallback(
        async (data: IAddOrganizationData) => {
            setLoading(true);
            try {
                if (await isOrganizationNameTaken(data.organizationName)) {
                    setTakenUsername(data.organizationName);
                    setLoading(false);
                    trigger('organizationName');
                    return;
                }
                await addOnPremOrganization(mapOnPremOrganizationDto(data, language));
                setLoading(false);
                close(true);
            } catch (error) {
                setShowDrawerError(true);
                setErrorMessage(await getErrorMessage(error));
                setLoading(false);
            }
        },
        [ close, isOrganizationNameTaken, language, trigger, getErrorMessage ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_ADD_ORGANIZATION' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            error={{
                showError: showDrawerError,
                message: errorMessage,
            }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => close()}
                            color="primary">
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={loading}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="add-organization-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                isDrawer
            >
                <>
                    <div className={classes.input}>
                        <Controller
                            control={control}
                            name="organizationName"
                            rules={{
                                required: true,
                                maxLength: accountSetting.accountLogicalNameLength,
                                validate: {
                                    available: value => value !== takenUsername,
                                    valid: value => validateAccountLogicalName(value),
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={translate({ id: 'CLIENT_ORGANIZATION_NAME' })}
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    InputLabelProps={{ id: 'organizationNameLabel' }}
                                    inputProps={{ 'aria-labelledby': 'organizationNameLabel' }}
                                    error={!!errors.organizationName}
                                    helperText={
                                        (errors.organizationName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                                        || (errors.organizationName?.type === 'available' && translate({ id: 'CLIENT_ORGANIZATION_NAME_TAKEN_ERROR' }))
                                        || ((errors.organizationName?.type === 'valid' || errors.organizationName?.type === 'maxLength')
                                            && translate(
                                                { id: 'CLIENT_INVALID_ACCOUNT_LOGICAL_NAME_ORG' },
                                                { 0: accountSetting.accountLogicalNameLength },
                                            ))
                                    }
                                    data-cy="add-organization-name"
                                />
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="adminUserName"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={translate({ id: 'CLIENT_ADMIN_USERNAME' })}
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    InputLabelProps={{ id: 'usernameLabel' }}
                                    inputProps={{ 'aria-labelledby': 'usernameLabel' }}
                                    error={!!errors.adminUserName}
                                    helperText={errors.adminUserName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                    data-cy="add-organization-admin-username"
                                />
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="adminFirstName"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={translate({ id: 'CLIENT_ADMIN_FIRST_NAME' })}
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    InputLabelProps={{ id: 'firstNameLabel' }}
                                    inputProps={{ 'aria-labelledby': 'firstNameLabel' }}
                                    error={!!errors.adminFirstName}
                                    helperText={errors.adminFirstName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                    data-cy="add-organization-admin-first-name"
                                />
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="adminLastName"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={translate({ id: 'CLIENT_ADMIN_LAST_NAME' })}
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    InputLabelProps={{ id: 'lastNameLabel' }}
                                    inputProps={{ 'aria-labelledby': 'lastNameLabel' }}
                                    error={!!errors.adminLastName}
                                    helperText={errors.adminLastName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                    data-cy="add-organization-admin-last-name"
                                />
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                                validate: { valid: value => validateEmail(value) },
                            }}
                            name="adminEmail"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={translate({ id: 'CLIENT_ADMIN_EMAIL' })}
                                    type="email"
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    InputLabelProps={{ id: 'adminEmailLabel' }}
                                    inputProps={{ 'aria-labelledby': 'adminEmailLabel' }}
                                    error={!!errors.adminEmail}
                                    helperText={
                                        errors.adminEmail
                                            ? (errors.adminEmail?.type === 'valid' && translate({ id: 'CLIENT_INVALID_EMAIL_ERROR' }))
                                              || (errors.adminEmail?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                                            : translate({ id: 'CLIENT_EMAIL_HELPER_TEXT' })
                                    }
                                    data-cy="add-organization-admin-email"
                                />
                            )}
                        />
                    </div>
                    <FormProvider {...methods}>
                        <EditPasswordFormComponent
                            respectPasswordRequirements
                            required
                            passwordLabel={translate({ id: 'CLIENT_ADMIN_PASSWORD' })}
                        />
                    </FormProvider>
                </>
            </UiForm>
        </UiDrawer>
    );
};

export default AddOrganizationComponent;
