import type {
    IFile,
    IRequestProps,
} from '@experiences/interfaces';
import {
    axiosDelete as axiosDeleteUtil,
    get as getUtil,
    getFile as getFileUtil,
    patch as patchUtil,
    post as postUtil,
    put as putUtil,
} from '@experiences/util';

import { getToken } from '../../auth/utils/Token';

export async function get<T>(url: string, requestProps?: IRequestProps): Promise<T> {
    const accessToken = requestProps?.accessToken ?? await getToken() as string;

    return getUtil<T>(url, {
        ...requestProps,
        accessToken,
    });
}

export async function getFile(url: string, requestProps?: IRequestProps): Promise<IFile> {
    const accessToken = requestProps?.accessToken ?? await getToken() as string;

    return getFileUtil(url, {
        ...requestProps,
        accessToken,
    });
}

export async function post<T>(url: string, requestProps?: IRequestProps) {
    const accessToken = requestProps?.accessToken ?? await getToken() as string;

    return postUtil<T>(url, {
        ...requestProps,
        accessToken,
    });
}

export async function put<T>(url: string, requestProps?: IRequestProps) {
    const accessToken = requestProps?.accessToken ?? await getToken() as string;

    return putUtil<T>(url, {
        ...requestProps,
        accessToken,
    });
}

export async function patch<T>(url: string, requestProps?: IRequestProps) {
    const accessToken = requestProps?.accessToken ?? await getToken() as string;

    return patchUtil<T>(url, {
        ...requestProps,
        accessToken,
    });
}

export async function axiosDelete(url: string, requestProps?: IRequestProps) {
    const accessToken = requestProps?.accessToken ?? await getToken() as string;

    return axiosDeleteUtil(url, {
        ...requestProps,
        accessToken,
    });
}
