import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        descriptionListItemText: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        listItemIcon: {
            margin: '8px 4px 0 0',
            minWidth: 'auto',
        },
        bullet: { fontSize: '6px' },
        includedServiceItem: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'left',
            marginTop: '18px',
        },
        listContainer: {
            marginTop: '24px',
            overflow: 'hidden',
        },
        listItem: {
            padding: 0,
            alignItems: 'flex-start',
        },
    }),
}));
const ProductDescriptionList: React.FC<{
    productCode: string;
}> = ({ productCode }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const translatedInfoList = useMemo(() => {
        const infoListTranslated: string[] = [];
        let iterator = 0;
        let foundTranslation = true;
        while (foundTranslation) {
            const translationKey = `CLIENT_ECOMMERCE_PRODUCT_LEARN_MORE_DESCRIPTION_${productCode}_${iterator}`;
            const translatedString = translate({ id: translationKey });
            iterator = iterator + 1;
            if (translatedString !== translationKey) {
                infoListTranslated.push(translatedString);
            } else {
                foundTranslation = false;
            }
        }
        return infoListTranslated;
    }, [ productCode, translate ]);

    return (
        <div className={classes.listContainer}>
            <List
                dense
                disablePadding>
                {translatedInfoList.map((item, index) =>
                    (<ListItem
                        key={index}
                        data-cy={`ecommerce-learn-more-description-${productCode}-${index}`}
                        disableGutters
                        className={classes.listItem}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <FiberManualRecordIcon className={classes.bullet} />
                        </ListItemIcon>
                        <ListItemText>
                            <UiText className={classes.descriptionListItemText}>
                                {item}
                            </UiText>
                        </ListItemText>
                    </ListItem>))}
            </List>
        </div>
    );
};

export default ProductDescriptionList;
