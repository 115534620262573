import { useLocalization } from '@experiences/locales';
import { GlobalStyles } from '@experiences/theme';
import {
    UiAlertBanner,
    UiLogo,
    UiText,
} from '@experiences/ui-common';
import {
    loadOrganizationApp,
    UiStorage,
    useAuthContext,
    useInterval,
    useModalState,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import type { TransitionProps } from '@mui/material/transitions';
import {
    makeStyles,
    useTheme,
} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedDate,
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useLocation } from 'react-router-dom';

import {
    DIRECT_BUY_ORDER_CONFIRMATION,
    formatDate,
    getPriceString,
    getTaxPercentageString,
    MARKETO_DATA,
    useIsSignupDirectBuyFlow,
} from './helpers/EcommerceHelpers';
import {
    BuyPro,
    BuyProSuccess,
} from './helpers/EcommerceRoutes';
import type {
    ICheckOrganizationResponse,
    IEcommerceSuccess,
    IOrderConfirmation,
} from './interfaces/ecommerce';
import { isOrganizationReady } from './services/BillingService';
import { EcommercePrice } from './subcomponents/EcommercePrice';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        dialog: { padding: '0px' },
        backBar: {
            height: '76px',
            width: '100%',
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: theme.palette.semantic.colorBorderDeEmp,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: theme.palette.semantic.colorBackground,
            justifyContent: 'space-between',
            padding: '22px',
        },
        title: {
            width: '530px',
            height: '24px',
            marginTop: '29px',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            color: theme.palette.semantic.colorForeground,
        },
        directBuyTitle: {
            width: '530px',
            height: '72px',
            marginTop: '29px',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            color: theme.palette.semantic.colorForeground,
            whiteSpace: 'pre-wrap',
        },
        receipt: {
            marginTop: '8px',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        orderConfirmationBody: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '32px',
        },
        goToAutomationCloud: { marginTop: '16px' },
        orderDetails: {
            marginTop: '36px',
            width: '529px',
            background: theme.palette.semantic.colorBackground,
            border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            boxSizing: 'border-box',
            borderRadius: '3px',
            padding: '24px 36px 28px 36px',
        },
        detailsTitle: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '24px',
            textAlign: 'center',
            color: theme.palette.semantic.colorForeground,
        },
        paymentDetails: {
            display: 'flex',
            marginTop: '32px',
            justifyContent: 'space-between',
        },
        paymentColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        paymentColumnTitle: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        paymentColumnValue: {
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        paymentSummary: {
            margin: '22px 0 8px',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        productsTable: {
            background: theme.palette.semantic.colorBackground,
            border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            boxSizing: 'border-box',
            borderRadius: '3px',
            padding: '24px 26px 20px 24px',
        },
        contact: {
            marginTop: '16px',
            textAlign: 'center',
        },
        contactText: {
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        productListContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        productListColumnAlignedToLeft: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        productListColumnAlignedToRight: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        productListColumnTitle: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '12px',
        },
        productListColumnValue: { marginBottom: '8px' },
        textValue: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        subtotal: {
            display: 'flex',
            borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            justifyContent: 'space-between',
            marginTop: '8px',
            paddingTop: '16px',
        },
        tax: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '8px',
        },
        amountPaid: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '8px',
        },
        amountPaidValues: {
            color: theme.palette.semantic.colorForeground,
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
        },
        bannerContent: {
            width: '530px',
            marginRight: '10px',
        },
        titleMarginTop: { marginTop: '10px' },
    }),
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide
        direction="up"
        ref={ref}
        {...props} />;
});

export const EcommerceOrderConfirmationComponent: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigateWithParams();
    const location = useLocation();
    const getRoute = useRouteResolver();
    const isSignupDirectBuyFlow = useIsSignupDirectBuyFlow();

    const { formatMessage: translate } = useIntl();
    const {
        open, close: modalStateClose,
    } = useModalState(BuyPro);
    const orderConfirmation = useMemo(() => {
        const localStorageValue = UiStorage.getItem(DIRECT_BUY_ORDER_CONFIRMATION);
        if (localStorageValue) {
            return JSON.parse(localStorageValue) as IOrderConfirmation;
        }
        return location?.state?.orderConfirmation! as IOrderConfirmation;
    }, [ location ]);
    const {
        invoiceData, products,
    } = orderConfirmation;
    const language = useLocalization();

    const close = () => {
        UiStorage.removeItem(DIRECT_BUY_ORDER_CONFIRMATION);
        modalStateClose();
    };

    useEffect(() => {
        localStorage.removeItem(MARKETO_DATA);
    }, []);

    const { token } = useAuthContext();
    const [ isOrganizationReadyResult, setIsOrganizationReadyResult ] = useState<ICheckOrganizationResponse | null>(null);

    const checkIfOrganizationIsReady = useCallback(async () => {
        const result = await isOrganizationReady(orderConfirmation.organizationId!, token);
        setIsOrganizationReadyResult(result);
    }, [ token, orderConfirmation ]);

    useInterval(
        () => checkIfOrganizationIsReady(),
        isSignupDirectBuyFlow && !isOrganizationReadyResult?.ready ? 1000 : null,
    );

    const isBalanceCheckFailed = useMemo(
        () => [ 'INVALID', 'TIMED_OUT' ].includes(orderConfirmation?.firstPaymentBalanceCheckStatus),
        [ orderConfirmation ]);

    const getInvoiceSubtotal = useCallback(() => {
        if (invoiceData) {
            return (
                <EcommercePrice
                    value={invoiceData.subtotal}
                    loading={false}
                    gap
                    currency={invoiceData.currency}
                    dataCy="order-confirmation-price"
                />
            );
        }

        return null;
    }, [ invoiceData ]);

    const getSuccessDialogState = useCallback(() => {
        let title, content;

        if (location?.state?.isLicenseDecreaseExecuted) {
            title = 'CLIENT_INCREASE_DECREASE_LICENSE_SUCCESS_DIALOG_TITLE';
            content = 'CLIENT_INCREASE_DECREASE_LICENSE_SUCCESS_DIALOG_CONTENT';
        } else {
            title = 'CLIENT_PRORATED_PURCHASE_SUCCESS';
            content = 'CLIENT_PRORATED_NEW_LICENSES_RELEASED';
        }

        return {
            ecommerceSuccess:
            {
                title: translate({ id: title }),
                line1: translate({ id: content }, { nextPaymentDate: formatDate(location?.state?.nextPaymentDate, language) }),
                button: 'OK',
            } as IEcommerceSuccess,
        };
    }, [ translate, location, language ]);

    const renderContactAnchor = useCallback((chunk: React.ReactNode[]) => <a
        className={classes.a}
        href="mailto:cloud.billing@uipath.com"
        target="_blank"
        rel="noopener noreferrer">
        {chunk}
    </a>, [ classes.a ]);

    return (
        <Dialog
            className={classes.dialog}
            fullScreen
            open={open}
            onClose={() => close()}
            TransitionComponent={Transition}
            data-cy="online-confirmation-dialog"
            PaperProps={{
                style: {
                    padding: '0px',
                    backgroundColor: theme.palette.semantic.colorBackground,
                },
            }}
        >
            <div className={classes.backBar}>
                <UiLogo />
            </div>
            <div className={classes.orderConfirmationBody}>
                {isBalanceCheckFailed && (
                    <UiAlertBanner
                        type="warning"
                        closeable={false}
                        dataCy="balance-check-failure-banner">
                        <div className={classes.bannerContent}>
                            <UiText>
                                {translate({ id: 'CLIENT_ECOMMERCE_BALANCE_CHECK_FAILURE_BANNER' })}
                            </UiText>
                        </div>
                    </UiAlertBanner>
                )}
                {isSignupDirectBuyFlow ? (
                    <UiText className={clsx({
                        [classes.directBuyTitle]: true,
                        [classes.titleMarginTop]: isBalanceCheckFailed,
                    })}>
                        {translate({ id: 'CLIENT_DIRECT_BUY_PURCHASE_SUCCESS' })}
                    </UiText>
                ) : (
                    <UiText className={clsx({
                        [classes.title]: true,
                        [classes.titleMarginTop]: isBalanceCheckFailed,
                    })}>
                        {orderConfirmation?.status === 'PAYMENT_PROCESSED' ?
                            translate({ id: 'CLIENT_PURCHASE_SUCCESS' }) :
                            translate({ id: 'CLIENT_PURCHASE_PAYMENT_INITIALIZED' })}
                    </UiText>
                )}
                <UiText className={classes.receipt}>
                    {translate({ id: 'CLIENT_A_COPY_OF_THE_RECEIPT' }, { 0: invoiceData?.email })}
                </UiText>
                {orderConfirmation?.status === 'PAYMENT_OPEN' && (
                    <UiText className={classes.receipt}>
                        {translate({ id: 'CLIENT_EMAIL_CONFIRMATION_WHEN_TRANSACTION_COMPLETED' })}
                    </UiText>)}
                <Button
                    className={classes.goToAutomationCloud}
                    variant="contained"
                    disabled={isSignupDirectBuyFlow && !isOrganizationReadyResult?.ready}
                    onClick={() => {
                        if (isSignupDirectBuyFlow) {
                            loadOrganizationApp(isOrganizationReadyResult!.organizationName!, orderConfirmation.organizationId!,
                                { firstLogin: true });
                        } else {
                            if (orderConfirmation.invoiceData?.incremental) {
                                navigate(getRoute(BuyProSuccess, orderConfirmation.accountName), { state: getSuccessDialogState() });
                            } else {
                                navigate(getRoute(BuyProSuccess, orderConfirmation.accountName));
                            }
                        }
                    }}
                    data-cy="go-to-automation-cloud"
                >
                    {translate({ id: 'CLIENT_GO_TO_AUTOMATION_CLOUD' })}
                </Button>
                <div className={classes.orderDetails}>
                    <UiText className={classes.detailsTitle}>
                        {translate({ id: 'CLIENT_ORDER_CONFIRMATION' })}
                    </UiText>
                    <div className={classes.paymentDetails}>
                        <div
                            data-cy="order-confirmation-total"
                            className={classes.paymentColumn}>
                            <UiText className={classes.paymentColumnTitle}>
                                {translate({ id: 'CLIENT_AMOUNT_PAID' })}
                            </UiText>
                            <UiText className={classes.paymentColumnValue}>
                                {invoiceData && getPriceString(invoiceData.total, invoiceData.currency, language)}
                            </UiText>
                        </div>
                        <div className={classes.paymentColumn}>
                            <UiText className={classes.paymentColumnTitle}>
                                {translate({ id: 'CLIENT_DATE_PAID' })}
                            </UiText>
                            <UiText className={classes.paymentColumnValue}>
                                {invoiceData && (
                                    <FormattedDate
                                        value={invoiceData.created}
                                        year="numeric"
                                        month="short"
                                        day="numeric" />
                                )}
                            </UiText>
                        </div>
                        <div
                            data-cy="order-confirmation-card-details"
                            className={classes.paymentColumn}>
                            <UiText className={classes.paymentColumnTitle}>
                                {translate({ id: 'CLIENT_PAYMENT_METHOD' })}
                            </UiText>
                            <UiText
                                className={classes.paymentColumnValue}
                                style={{ wordWrap: 'break-word' }}>
                                {translate(
                                    { id: 'CLIENT_CREDIT_CARD_ENDING_IN' },
                                    (invoiceData?.paymentMethodType === 'card' || !(invoiceData?.paymentMethodType)) ?
                                        {
                                            0: translate({ id: `CLIENT_CREDIT_CARD_${invoiceData?.cardBrand.toUpperCase()}` }),
                                            1: invoiceData?.cardLast4,
                                        } :
                                        {
                                            0: translate({ id: `CLIENT_DIRECT_DEBIT` }),
                                            1: invoiceData?.ibanLast4 ?? invoiceData?.usBankAccountLast4,
                                        },
                                )}
                            </UiText>
                        </div>
                    </div>
                    <UiText className={classes.paymentSummary}>
                        {translate({ id: 'CLIENT_SUMMARY' })}
                    </UiText>
                    <div className={classes.productsTable}>
                        <div
                            data-cy="order-confirmation-products-and-subtotal"
                            className={classes.productListContainer}>

                            <div
                                className={classes.productListColumnAlignedToLeft}
                                data-cy="order-confirmation-products-code-column">
                                <UiText className={classes.productListColumnTitle}>
                                    {translate({ id: 'CLIENT_ECOMMERCE_ORDER_CONFIRMATION_PRODUCT_NAME' })}
                                </UiText>
                                {products?.map((product, index) => (
                                    <UiText
                                        className={clsx(classes.productListColumnValue, classes.textValue)}
                                        key={index}
                                        data-cy={`order-confirmation-product-name-${product.code}`}>
                                        {translate({ id: `CLIENT_PRODUCT_${product.code}` })}
                                        {orderConfirmation?.invoiceData?.incremental &&
                                            (' ' + (product.prorated ?
                                                translate({ id: 'CLIENT_PRORATED' }) :
                                                translate({ id: 'CLIENT_NON_PRORATED' })))}
                                    </UiText>
                                ))}
                            </div>

                            <div
                                className={classes.productListColumnAlignedToRight}
                                data-cy="order-confirmation-products-quantity-column">
                                <UiText className={classes.productListColumnTitle}>
                                    {translate({ id: 'CLIENT_ECOMMERCE_ORDER_CONFIRMATION_PRODUCT_QUANTITY' })}
                                </UiText>
                                {products?.map((product, index) => (
                                    <UiText
                                        className={clsx(classes.productListColumnValue, classes.textValue)}
                                        data-cy={`order-confirmation-product-quantity-${product.code}`}
                                        key={index}>
                                        {product.quantity}
                                    </UiText>
                                ))}
                            </div>

                            <div
                                className={classes.productListColumnAlignedToRight}
                                data-cy="order-confirmation-products-total-price-column">
                                <UiText className={classes.productListColumnTitle}>
                                    {translate({ id: 'CLIENT_ECOMMERCE_ORDER_CONFIRMATION_PRODUCT_TOTAL_PRICE' })}
                                </UiText>
                                {products?.map((product, index) => (
                                    <UiText
                                        className={clsx(classes.productListColumnValue, classes.textValue)}
                                        key={index}
                                        data-cy={`order-confirmation-product-price-${product.code}`}>
                                        {getPriceString(product.totalPrice, product.currency, language)}
                                    </UiText>
                                ))}
                            </div>
                        </div>
                        <div
                            data-cy="order-confirmation-subtotal"
                            className={classes.subtotal}>
                            <UiText className={classes.textValue}>
                                {translate({ id: 'CLIENT_SUBTOTAL' })}
                            </UiText>
                            {invoiceData && getInvoiceSubtotal()}
                        </div>
                        <div
                            data-cy="order-confirmation-tax"
                            className={classes.tax}>
                            <UiText className={classes.textValue}>
                                {invoiceData &&
                  `${translate({ id: 'CLIENT_TAX_TITLE' })}  ${getTaxPercentageString(invoiceData.taxRate, language)}`}
                            </UiText>
                            <UiText className={classes.textValue}>
                                {invoiceData && getPriceString(invoiceData.taxValue, invoiceData.currency, language)}
                            </UiText>
                        </div>
                        <div
                            data-cy="order-confirmation-amount-paid"
                            className={classes.amountPaid}>
                            <UiText className={classes.amountPaidValues}>
                                {translate({ id: 'CLIENT_AMOUNT_PAID' })}
                            </UiText>
                            <UiText className={classes.amountPaidValues}>
                                {invoiceData && getPriceString(invoiceData.total, invoiceData.currency, language)}
                            </UiText>
                        </div>
                    </div>
                    <div className={classes.contact}>
                        <UiText className={classes.contactText}>
                            {translate({ id: 'CLIENT_IF_YOU_HAVE_ANY_QUESTIONS' })}
                        </UiText>
                        <UiText className={classes.contactText}>
                            <FormattedMessage
                                id="CLIENT_PLEASE_CONTACT_UI_PATH_AT"
                                values={{ a: renderContactAnchor }}
                            />
                        </UiText>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
