import { useShowDialog } from '@experiences/util';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import EcommerceLearnMoreComponent from './EcommerceLearnMoreComponent/EcommerceLearnMoreComponent';

export const useShowLearnMoreEcommerceDialog = () => {
    const createDialog = useShowDialog();
    const { formatMessage: translate } = useIntl();

    return useCallback((productCode: string, titleLabel: string) => createDialog({
        title: translate({ id: titleLabel }),
        body: (
            <EcommerceLearnMoreComponent productCode={productCode} />
        ),
        showCancel: false,
        hideActions: true,
        dataCy: `ecommerce-learn-more-dialog-${productCode}`,
    }),
    [ createDialog, translate ]);
};

export default useShowLearnMoreEcommerceDialog;
